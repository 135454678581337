import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import thunk from 'redux-thunk'
import userSlice from './userSlice'
import { combineReducers } from '@reduxjs/toolkit';

const encryptor = encryptTransform({
  secretKey: process.env.REACT_APP_REDUX_STORAGE_SECRET_KEY,
  onError: function (error) {
    console.log(error)
  },
})
const userReducer = combineReducers({
  user: userSlice,
});
const persistConfig = {
  key: 'root',
  storage: storage,
  timeout: null,
  transforms: [encryptor]
};
const persistedReducer = persistReducer(persistConfig, userReducer);
const isDevelopment = process.env.NODE_ENV === 'development';
const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const ENABLE_REDUX_DEV_TOOLS = isDevelopment && isLocalhost;
export const store = configureStore({
  reducer: persistedReducer,
  devTools: ENABLE_REDUX_DEV_TOOLS,
  middleware: [thunk],
});
export const persistor = persistStore(store);
import moment from 'moment';
import React from 'react'
import DataTable from 'react-data-table-component';

export default function ClientSubApplicants({data}) {
    const columns = [
        {
            name: 'No.',
            cell: (row, index) => <div>{index + 1}</div>,
            width: '55px',
        },
        {
            name: 'Surname',
            cell: (row) => (
                <div>{row.surname}</div>
            ),
        },
        {
            name: 'Name',
            cell: (row) => (
                <div>{row.name}</div>
            ),
        },
        {
            name: 'Birth Date',
            cell: (row) => (
                <div>{row?.dob && moment(row?.dob).format("DD/MM/YYYY")}</div>
            ),
        },
        {
            name: 'Relation',
            cell: (row) => (
                <div>{row.relation}</div>
            ),
        },
        {
            name: 'Status',
            cell: (row) => (
                <div>{row.status?.name}</div>
            ),
        }
    ];
  return (
    <div className='p-1'>
         <h4 className='mb-0'>Family</h4>
        <React.Suspense fallback={<div>Loading...</div>}>
            <DataTable columns={columns} data={data} pagination striped paginationPerPage={10} paginationRowsPerPageOptions={[10, 20, 50]} />
        </React.Suspense>
</div>
  )
}

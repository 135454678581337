import React from 'react'
import { Link } from 'react-router-dom'

export default function FrontHeader() {
    return (
        <div className='container pt-3'>
            <div className="row card shadow flex-row">
                <div className="col-md-5">
                    <Link to="/"> <img src='/logo.png' alt='logo' className='logo py-3' style={{ maxHeight: '80px' }} /></Link>
                </div>
                <div className="col-md-7 d-flex justify-content-end pt-1">
                    {/* <ul class="nav mt-3">
                        <li class="nav-item">
                            <Link className='nav-link active text-capitalize' aria-current="page" to="/">Home</Link>
                        </li>
                        <li class="nav-item">
                            <Link className='nav-link active text-capitalize' aria-current="page" to="/news">News</Link>
                        </li>
                    </ul> */}
                </div>
            </div>
        </div>
    )
}

import React from 'react'
import { Link } from 'react-router-dom'

export default function FrontFooter() {
    return (
        <div className="container">
            <div className="row d-flex justify-content-center border-bottom">
                <div className="col-md-12 d-flex justify-content-center">
                    <ul className='nav mb-3'>
                        <li className='me-2'> <Link to='/terms-conditions'>Terms and Conditions</Link> </li>
                        <li className='border-end'></li>
                        <li className='ms-2'> <Link to="/privacy-policy">Privacy Policy</Link> </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

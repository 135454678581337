import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import "./timeline.css";


const HorizontalTimeline = ({ clientId }) => {
    const [timelineData, setTimelineData] = useState([]);
    const [loading, setLoading] = useState(true);
    // Find the last stage with a date
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/clients/timeline/` + clientId, { withCredentials: true });
                if (response.data) {
                    setTimelineData(response.data.workingDaysData);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching timeline data:', error);
            }
        };
        fetchData();
    }, [clientId]);

    return (
        <div>
            {loading ? (
                // Custom placeholder while loading
                <div className='card placeholder-glow w-100 my-3' style={{ minHeight: '200px' }}>
                    {[...Array(4)].map((_, index) => (
                        <span className="my-3 placeholder" key={index}></span>
                    ))}
                </div>
            ) : <>
                <div className="row mt-5">
                    <div className="col-12 d-flex justify-content-center">
                        <div className="timeline-steps">
                            {timelineData.map((stage, index) => (
                                <div key={index} className="timeline-step">
                                    <div className="timeline-content"
                                        data-content={`Stage: ${stage.label}\nDate: ${stage.date ? moment(stage.date).format('DD/MM/YYYY') : 'Awaiting'}`}>
                                        {stage.date ? (
                                            <>
                                                <div className="inner-circle"></div>
                                                <p className="h6 mt-3 mb-1">{moment(stage.date).format('DD/MM/YYYY')}</p>
                                            </>
                                        ) : (
                                            <>
                                                <div className="inner-circle-gray"></div>
                                                <p className="h6 mt-2 text-warning mb-1">Awaiting</p>
                                            </>
                                        )}
                                        <p className="h6 text-info mb-0 mb-lg-0" style={{ height: '40px' }}>{stage.label}</p>
                                        <h6>{stage?.workingDays === 0 ? '' : `${stage?.workingDays} ${stage?.workingDays === 1 ? 'day' : 'days'}`}</h6>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ul className=''>
                            <li>Displayed days do not include weekends or public holidays.</li>
                        </ul>
                    </div>
                </div>
            </>
            }
        </div>
    );
};

export default HorizontalTimeline;
import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLogged: false,
    userDetails: {},
    isRequestOTP: false,
    clientEmail: '',
  },
  reducers: {
    setIsLogged: (state, action) => {
      state.isLogged = action.payload
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    setIsRequestOTP: (state, action) => {
      state.isRequestOTP = action.payload
    },
    setClientEmail: (state, action) => {
      state.clientEmail = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setIsLogged, setUserDetails,setIsRequestOTP,setClientEmail } = userSlice.actions

export default userSlice.reducer
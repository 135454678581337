import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setIsLogged, setUserDetails } from '../../redux/userSlice';
import { useNavigate } from 'react-router-dom';
import { BsEye, BsEyeSlash } from "react-icons/bs"
import LoaderSpinner from '../../components/Loader/LoaderSpinner';

export default function Login() {
    const [login, setLogin] = useState({
        email: "",
        password: ""
    });
    const { email, password } = login
    const onInputChange = e => {
        setLogin({ ...login, [e.target.name]: e.target.value });
    };
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [InvalidMessage, setInvalidMessage] = useState();
    const [IsLoading, setIsLoading] = useState(false);
    const [PasswordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!PasswordShown); };
    const SubmitHandler = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true)
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/users/login`, login, { withCredentials: true }).catch(function (error) {
                if (error.response) {
                    if (error.response.status === 401 || 500) {
                        throw new Error("Email or Password Invalid");
                    }
                }
            });
            const user = response.data
            if (user) {
                dispatch(setIsLogged(true))
                dispatch(setUserDetails(user))
                navigate("/")
            } else {
                throw new Error("Email or Password Invalid");
            }
        } catch (error) {
            setIsLoading(false)
            setInvalidMessage(error.message);
            setTimeout(() => {
                setInvalidMessage()
            }, 2000)
        }
    }
    useEffect(() => {
        document.title = 'FastTrack - Login';
    }, []);

    return (
        <div className='row d-flex justify-content-center'>
            <div className="col-md-4 p-3">
                <h4 className="mb-2">Welcome back to FastTrack! 👋</h4>
                <p className="mb-4">Please sign-in to your account to get started</p>
                {IsLoading && <LoaderSpinner />}
                {InvalidMessage && <div className="alert alert-danger" role="alert">{InvalidMessage} </div>}
                <form onSubmit={SubmitHandler}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="Enter your email" name="email" value={email} onChange={e => onInputChange(e)} autoFocus />
                    </div>
                    <div className="mb-3 form-password-toggle">
                        <div className="d-flex justify-content-between">
                            <label className="form-label" htmlFor="password">Password</label>
                        </div>
                        <div className="input-group">
                            <input type={PasswordShown ? "text" : "password"} name='password' value={password} onChange={e => onInputChange(e)} className="form-control" id="password" placeholder="Enter Your Password" required />
                            <span className="input-group-text" id="basic-addon1">{PasswordShown ? <BsEyeSlash onClick={() => togglePassword()} /> : <BsEye onClick={() => togglePassword()} />}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="remember-me" />
                            <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                        </div>
                    </div>
                    <div className="mb-3">
                        <button className="btn btn-primary d-grid w-100" type="submit">Sign in</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import "./BankStatusStyle.css";

export default function BankStatus(props) {
    const { currentStep } = props;
    const bankSteps = [
        "Submitted by Client",
        "Reviewed by IIS",
        "Submitted to Bank",
        "Bank Compliance Review",
        "Account Approved",
        "Online Access Approved",
        "Bank Card Mailed",
        "Process Complete"
    ];

    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Bank Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="steps-container">
                    {bankSteps.map((step, index) => (
                        <div
                            key={index}
                            className={`step-item ${
                                index <= bankSteps.indexOf(currentStep) ? "active" : 
                                index === (bankSteps.indexOf(currentStep)+1) ? 'loading' : ""
                              }`}
                        >
                           <div className='icon'>
                           {index <= bankSteps.indexOf(currentStep) ? "✅" : 
                                index === (bankSteps.indexOf(currentStep)+1) ? '⏳' : "❗"}
                           </div>
                            <p className='text-center fw-bold'>{step}</p>
                        </div>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
}
import axios from 'axios';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClientEmail, setIsRequestOTP } from '../../redux/userSlice';
import Spinner from 'react-bootstrap/Spinner';
import ClientResult from './ClientResult';

export default function Home() {
    const API_URL = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const { isRequestOTP } = useSelector(state => state.user);
    const [searchData, setSearchData] = useState({
        email: "",
        otp: ""
    });
    const { email, otp } = searchData
    const onInputChange = e => {
        setSearchData({ ...searchData, [e.target.name]: e.target.value });
    };
    const [loading, setLoading] = useState(false);
    const [clientData, setClientData] = useState(null);
    const [InvalidMessage, setInvalidMessage] = useState();
    const [SuccessMessage, setSuccessMessage] = useState();
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Make an API call to search for the client
            const response = await axios.post(`${API_URL}/clients/generate-otp`, { email }, { withCredentials: true }).catch(function (error) {
                if (error.response) {
                    if (error.response.status === 404 || error.response.status === 500) {
                        throw new Error(error.response.data.message); // Throw an error with the API message
                    }
                }
            });
            if (response.data) {
                // Set isRequestOTP to true after successful OTP request
                dispatch(setClientEmail(email));
                dispatch(setIsRequestOTP(true));
                setLoading(false);
                setSuccessMessage("An OTP code has been sent to your email address");
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000)
            }
        } catch (error) {
            setInvalidMessage(error.message); // Show the error message from the API
            setTimeout(() => {
                setInvalidMessage()
            }, 3000)
        } finally {
            setLoading(false);
        }
    }

    const handleResendOTP = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${API_URL}/clients/generate-otp`, { email }, { withCredentials: true });
            if (response.data) {
                setLoading(false);
                setSuccessMessage("A new OTP code has been sent to your email address");
                setTimeout(() => {
                    setSuccessMessage()
                }, 2000)
            }
        } catch (error) {
            setInvalidMessage("Failed to resend OTP. Please try again.");
            setLoading(false);
        }
    }

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Make an API call to search for the client
            const response = await axios.post(`${API_URL}/clients/search`, searchData, { withCredentials: true });

            if (response.data) {
                dispatch(setIsRequestOTP(false));
                setClientData(response.data);
            }

            setLoading(false);
        } catch (error) {
            if (error.response && (error.response.status === 404 || error.response.status === 500)) {
                setInvalidMessage(error.response.data.message);
            } else {
                setInvalidMessage('An error occurred while processing your request. Please try again later.');
            }
            setTimeout(() => {
                setInvalidMessage()
            }, 3000)
            setLoading(false);
        }
    }

    const handleCancel = () => {
        dispatch(setIsRequestOTP(false));
        dispatch(setClientEmail(''));
        setSearchData({ email: "", otp: "" }); 
        setInvalidMessage(''); 
    }
    return (
        <div className="row my-3 pt-5">
            <div className="col-md-12 text-center">
                <h4 className='mb-4 text-black'>Monitor Your Portuguese Visa Application Progress</h4>
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        {InvalidMessage && <div className="alert alert-danger mt-2 text-dark border border-danger" role="alert">{InvalidMessage} </div>}
                        {SuccessMessage && <div className="alert alert-success mt-2 text-dark border border-success" role="alert">{SuccessMessage} </div>}
                        {loading && <Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                {clientData ? <ClientResult clientData={clientData} setClientData={setClientData} setSearchData={setSearchData} /> : isRequestOTP ?
                    <form className='search-form' onSubmit={handleOTPSubmit} autocomplete="off">
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-3"><label htmlFor="otp" className="form-label">OTP</label></div>
                                <div className='col-md-9'>
                                    <input type="text" className="form-control" value={otp} id="otp" onChange={e => onInputChange(e)} placeholder="Enter your OTP code" name="otp" autoFocus required />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-center">
                            <button className="btn btn-primary" type="submit">Login</button>
                        </div>
                        <div className="mb-3 d-flex justify-content-center">
                            <button className="btn btn-link text-primary" type="button" onClick={handleResendOTP}>Resend OTP</button>
                            <button className="btn btn-link text-primary" type="button" onClick={handleCancel}>Cancel</button>
                        </div>
                    </form>
                    :
                    <form className='search-form' onSubmit={handleSubmit} autocomplete="off">
                        <div className="mb-3">
                            <div className="row">
                                <div className="col-md-12 d-flex justify-content-center"><label htmlFor="email" className="form-label">Enter your email and submit to get started</label></div>
                            </div>
                            <div className="row d-flex justify-content-center">
                                <div className='col-md-8 '>
                                    <input type="email" className="form-control" value={email} id="email" onChange={e => onInputChange(e)} placeholder="Enter your email" name="email" autoFocus required />
                                </div>
                            </div>
                        </div>
                        <div className="mb-3 d-flex justify-content-center">
                            <button className="btn btn-primary" type="submit">Submit</button>
                        </div>
                    </form>
                }
            </div>
        </div>
    )
}
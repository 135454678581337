import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi';

export default function NewsList() {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);

    const getNews = async () => {
        await axios.get(`${process.env.REACT_APP_API_URL}/cms/type/news`)
            .then(response => {
                setNews(response.data);
                setLoading(false); // Set loading to false when data arrives
            })
            .catch(error => {
                console.error('Error fetching privacy policy:', error);
                setLoading(false); // Set loading to false on error as well
            });
    }
    useEffect(() => {
        getNews();
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className="row p-3">
            <div className="col-md-12">
                <Link to="/" className='text-info d-flex flex-row align-items-center'><BiArrowBack className='me-1' /> Back to Home</Link>
                <h4 className='mt-3'>News</h4>
                <div className="row justify-news-center">
                    <div className="col-md-12">
                        {loading ?
                            <div style={{ width: '100%', minHeight: '800px', background: '#f0f0f0' }}>
                                {[...Array(8)].map((_, index) =>
                                    <p class="card-text placeholder-glow" key={index}>
                                        <span class="placeholder col-12"></span>
                                        <span class="placeholder col-12"></span>
                                        <span class="placeholder col-12"></span>
                                        <span class="placeholder col-8"></span>
                                        <span class="placeholder col-12"></span>
                                    </p>
                                )}
                            </div>
                            :
                            <div>
                                <ul class="list-group">
                                    {news && news.length>0 && news.map((item,index)=>(
                                         <li class="list-group-item fw-bold" key={index}> <Link to={`/news/${item?.slug}`} className='text-dark'>{item?.title}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState } from "react"; // Add startTransition
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import FrontHeader from "./components/Layout/FrontHeader";
import FrontFooter from "./components/Layout/FrontFooter";
import SalesIQ from "./components/Chat/SalesIQ";
import 'react-data-table-component-extensions/dist/index.css';
import 'jquery/dist/jquery.min.js';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import './App.css';
import PrivacyPolicy from "./pages/Home/PrivacyPolicy";
import TermsConditions from "./pages/Home/TermsConditions";
import NewsList from "./pages/Home/News/NewsList";
import NewsDetail from "./pages/Home/News/NewsDetail";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Header = React.lazy(() => import("./components/Layout/Header"));
const TopNav = React.lazy(() => import("./components/Layout/TopNav"));
const Footer = React.lazy(() => import("./components/Layout/Footer"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const StatusTypes = React.lazy(() => import("./pages/Settings/StatusTypes/StatusTypes"));
const AddClients = React.lazy(() => import("./pages/Clients/AddClients"));
const Clients = React.lazy(() => import("./pages/Clients/Clients"));
const AdvanceClientTable = React.lazy(() => import("./pages/Reports/AdvanceClientTable"));
const EditClient = React.lazy(() => import("./pages/Clients/EditClient"));
const ViewClient = React.lazy(() => import("./pages/Clients/ViewClient"));
const BulkClientUpload = React.lazy(() => import("./pages/Clients/BulkClientUpload"));
const Users = React.lazy(() => import("./pages/Users/Users"));
const AddUser = React.lazy(() => import("./pages/Users/AddUser"));
const EditUser = React.lazy(() => import("./pages/Users/EditUser"));
const Profile = React.lazy(() => import("./pages/Profile/Profile"));
const Settings = React.lazy(() => import("./pages/Settings/Settings"));
const Holidays = React.lazy(() => import("./pages/Settings/Holidays/Holidays"));
const Logs = React.lazy(() => import("./pages/Reports/Logs"));
const Pages = React.lazy(() => import("./pages/CMS/Pages/Pages"));
const AddPage = React.lazy(() => import("./pages/CMS/Pages/AddPage"));
const EditPage = React.lazy(() => import("./pages/CMS/Pages/EditPage"));
const Newses = React.lazy(() => import("./pages/CMS/News/Newses"));
const AddNews = React.lazy(() => import("./pages/CMS/News/AddNews"));
const EditNews = React.lazy(() => import("./pages/CMS/News/EditNews"));

function App() {
  const isLogged = useSelector((state) => state.user?.isLogged);
  const [ToggleNav, setToggleNav] = useState(false);
  const currentYear = new Date().getFullYear();
  return (
    <BrowserRouter>
      <div className="App">
        {isLogged ?
          <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
              <React.Suspense fallback={<div>Loading...</div>}>
                <Header ToggleNav={ToggleNav} />
                <div className="layout-page">
                  <TopNav setToggleNav={setToggleNav} ToggleNav={ToggleNav} />
                  <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/admin" element={<Dashboard />} />
                        {/* Admin routes */}
                        <Route path="/admin/users" element={<Users />} />
                        <Route path="/admin/add-user" element={<AddUser />} />
                        <Route path="/admin/edit-user/:userId" element={<EditUser />} />
                        {/* Clients routes */}
                        <Route path="/admin/clients" element={<Clients />} />
                        <Route path="/admin/add-client" element={<AddClients />} />
                        <Route path="/admin/bulk-client" element={<BulkClientUpload />} />
                        <Route path="/admin/edit-client/:clientId" element={<EditClient />} />
                        <Route path="/admin/view-client/:clientId" element={<ViewClient />} />
                        {/* Reports routes */}
                        <Route path="/admin/reports/clients" element={<AdvanceClientTable />} />
                        <Route path="/admin/reports/logs" element={<Logs />} />
                        {/* Settings routes */}
                        <Route path="/admin/profile" element={<Profile />} />
                        <Route path="/admin/settings" element={<Settings />} />
                        <Route path="/admin/holidays" element={<Holidays />} />
                        <Route path="/admin/status-types" element={<StatusTypes />} />
                        {/* CMS routes */}
                        <Route path="/admin/pages" element={<Pages />} />
                        <Route path="/admin/add-page" element={<AddPage />} />
                        <Route path="/admin/edit-page/:pageId" element={<EditPage />} />
                        <Route path="/admin/news" element={<Newses />} />
                        <Route path="/admin/add-news" element={<AddNews />} />
                        <Route path="/admin/edit-news/:newsId" element={<EditNews />} />
                      </Routes>
                    </div>
                    <Footer />
                    <div className="content-backdrop fade"></div>
                  </div>
                </div>
              </React.Suspense>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
          </div>
          :
          <div className='container-fluid' style={{ backgroundImage: `URL('/assets/img/fasttrack-bg.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundAttachment: 'fixed', minHeight: '100vh' }}>
            <FrontHeader />
            <div className='container' >
              <div className="row">
                <div className="col d-flex justify-content-center align-items-center p-1" style={{ minHeight: '84vh' }}>
                  <div className="card tracking-page-card shadow py-2">
                    <Routes>
                      <Route path="/" element={<Home />} />
                      <Route path="/partner" element={<Login />} />
                      <Route path="/admin" element={<Login />} />
                      <Route path="/news" element={<NewsList />} />
                      <Route path="/news/:slug" element={<NewsDetail />} />
                      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                      <Route path="/terms-conditions" element={<TermsConditions />} />
                    </Routes>
                    <FrontFooter />
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <p className="text-black mb-0 pt-2 ps-3 text-center text-md-start"> &copy; {currentYear} FastTrack.iis.pt</p>
                      </div>
                      <div className="col-sm-12 col-md-7">
                        <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
                          <p className="text-black mb-0 pt-2 pe-3 text-center text-md-start"><a href="https://maps.app.goo.gl/dkAycqbY8Mrd5jtb9" target="_black">R. Olavo d' Eça Leal 6A, 1600-306 Lisboa.</a></p>
                          <p className="text-black mb-0 pt-2 pe-3 text-center text-md-start"><a href="tel:+351963866317">+351 963 866 317</a></p>
                          <p className="text-black mb-0 pt-2 pe-3 text-center text-md-start"><a href="mailto:fasttrack@iis.pt">fasttrack@iis.pt</a></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <SalesIQ />
          </div>
        }
      </div>
    </BrowserRouter>
  );
}

export default App;
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import HorizontalTimeline from '../../components/Timeline/HorizontalTimeline';
import { setClientEmail, setIsRequestOTP } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import ClientSubApplicants from './ClientSubApplicants';
import BankStatus from '../../components/Modal/BankStatus';

export default function ClientResult({ clientData, setClientData, setSearchData }) {
    const API_URL = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const handleCancel = () => {
        dispatch(setIsRequestOTP(false));
        dispatch(setClientEmail(''));
        setSearchData({ email: "", otp: "" });
        setClientData(null)
    }
    const [subApplicants, setSubApplicants] = useState([]);
    const getSubApplicants = async (clientId) => {
        const response = await axios.get(`${API_URL}/clients/sub-applicants/` + clientId, { withCredentials: true })
        if (response) {
            setSubApplicants(response.data)
        }
    }
    useEffect(() => {
        if (clientData) {
            getSubApplicants(clientData?._id);
        }
        // eslint-disable-next-line
    }, [clientData]);
    const [modalShow, setModalShow] = useState(false);
    const [currentStatus, setCurrentStatus] = useState();
    
    return (
        <div className='search-result m-3'>
            <div className='card tracking-page-card p-3 shadow mb-3'>
                <div className="row mb-1">
                    <div className="col-12 col-md-6"><strong>Name</strong>: {clientData.name} {clientData.surname}</div>
                    <div className="col-12 col-md-6 text-start text-md-end"><strong>Status</strong>: <span className='badge badge-info'>{clientData.status.name}</span></div>
                </div>
                <div className="row mb-1">
                    <div className="col-12 col-md-6"><strong>Visa Type</strong>: Golden Visa</div>
                    <div className="col-12 col-md-6 text-start text-md-end"><strong>Process #</strong>: {clientData?.processingNo}</div>
                </div>
                <div className="row mb-1">
                    <div className="col-12 col-md-6">
                        <strong>Bank Account Opening Status</strong>: <span className='badge badge-primary bg-primary'> {clientData?.bankAccountStatus}</span>
                        <button className='btn btn-sm' onClick={() => { setCurrentStatus(clientData?.bankAccountStatus); setModalShow(true) }}>View More</button>
                    </div>
                    <div className="col-12 col-md-6 text-start text-md-end"></div>
                </div>
                <div className="row">
                    <div className="col-md-12 ">
                        <HorizontalTimeline clientId={clientData?._id} />
                    </div>
                </div>
                {subApplicants && subApplicants.length > 0 ? <ClientSubApplicants data={subApplicants} /> : ''}
                <div className="row mb-1">
                    <div className="col-12 col-md-6"><strong>Last Update</strong>: {moment(clientData?.updateAt).format("DD/MM/YYYY")}</div>
                    <small>TRC = Temporary Resident Card</small>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <button className='btn btn-secondary' onClick={handleCancel}>Back</button>
            </div>
            <BankStatus
                show={modalShow}
                onHide={() => setModalShow(false)}
                currentStep={currentStatus}
                setModalShow={setModalShow}
            />
        </div>
    )
}
